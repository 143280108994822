import React, { ReactElement, ReactNode, useContext } from "react"
import reactStringReplace from "react-string-replace"
import tw from "twin.macro"
import twConfig from "../tailwind.config.js"
import { CTA, Model, RGBArray, TWColor } from "./global.js"
import parse from "html-react-parser"
import { DisclaimerPopover } from "./components/molecules/DisclaimerPopover"
import { InventoryParams } from "./clients/InventoryClient.d"
import { Buffer } from "buffer"
import { DisclaimersContext } from "./contexts/Disclaimers/context"
import { Disclaimer } from "./contexts/Disclaimers/disclaimers.d"
import { Filter } from "./clients/FiltersClient.d"
import slugifyFn from "slugify"
import { Coupon } from "./components/molecules/Coupons/Coupons.d.js"
import moment from "moment"

/**
 * Converts a hex color string (#nnnnnn) into an RGBArray
 *
 * @param hex - Hex code of color to convert
 *
 * @returns {RGBArray} - [R, G, B]
 */
export const hexToRgb = (hex: string): RGBArray | null => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null
}

/**
 * Calculates the brightness of a color from 0 to 255
 *
 * @param {RGBArray} rgb - [R, G, B] formatted color array to get brightness off
 *
 * @returns {number} - Brightness, 0-255
 */
export const getBrightness = (rgb: RGBArray): number => {
  if (rgb === null) return 0
  return Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000)
}

/**
 * Convert a tailwind color name into its configured hex value
 *
 * @param {TWColor} twColor - A tailwind color (e.g. red-400)
 *
 * @returns {string} - Hex value of the tailwind color, if it exists
 */
export const getTWColorHex = (twColor: TWColor | string): string => {
  const [baseColor, shade] = twColor.split("-")
  if (typeof twConfig.theme.colors[baseColor] === "string") {
    return twConfig.theme.colors[baseColor]
  } else {
    return twConfig.theme.colors[baseColor][shade]
  }
}

export const processIconColor = (color: string | TWColor): string => {
  if (!color) {
    return null
  } else {
    return color[0] === "#" ? color : getTWColorHex(color)
  }
}

/**
 * Parse out disclaimer codes within text and replace them with Disclaimer Popovers
 *
 * @param {string} text - Text to parse.
 * @param {function} cb - onClick function for View All Disclaimers button inside popover.
 * @param {number} parentLeft - Optional DOMRect left value for parent element. Used to reposition disclaimers to prevent popover from being outside of parent container. Default behavior is to reposition the popover to keep it within document clientWidth.
 * @param {number} parentRight - Optional DOMRect right value for parent element. Used to reposition disclaimers to prevent popover from being outside of parent container. Default behavior is to reposition the popover to keep it within document clientWidth.
 * @returns text with disclaimer codes [example_code] replaced with DisclaimerPopover component
 */

export const parseDisclaimerBlocks = (
  text: ReactNode | string,
  cb: (code: string) => void,
  parentLeft?: number,
  parentRight?: number,
  width?: number,
  height?: number,
): any => {
  return reactStringReplace(parse(`${text}`), /\[(.*?)\]/gi, (match, i) => (
    <DisclaimerPopover
      onClick={() => cb(match)}
      code={match}
      parentLeft={parentLeft}
      parentRight={parentRight}
      width={width}
      height={height}
    />
  ))
}

/**
 * remove a disclaimer string from given text.
 *
 * @param {string} x - 24970
 * @returns {string} - $24,970
 */
export const stripDisclaimerCodes = (text: string): string => {
  return text?.replace(/\[(.*?)\]/gi, "").trim()
}
/**
 * Format a number as USD
 *
 * @param {number} x - 24970
 * @returns {string} - $24,970
 */
export const toUsd = (x: number): string => {
  if (x % 1 != 0) {
    return `${Number(x)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
  } else {
    return `${Number(x)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
  }
}
/**
 * Maps GraphQL edges to notes
 *
 * @param {any} data - object.edges.node.value
 * @returns {array} - object.value
 */
export const mapEdgesToNodes = (data: any): any[] => {
  if (!data.edges) return []
  return data.edges.map((edge: any) => edge.node)
}

/**
 * Applies letter spacing to a string. CSS 'letter-spacing' offsets alignment, this doesn't
 *
 * @param {string} string - The string to split
 * @returns {ReactElement} - <div><span></span></div>
 */
export const letterSpacing = (string: string) => {
  const split = string?.split("")
  if (string !== undefined && string !== null)
    return (
      <div
        css={[tw`flex gap-2.5`, tw`md:(gap-2)`, tw`lg:(gap-3)`, tw`xl:(gap-5)`]}
      >
        {split.map((character: string) => (
          <span>{character}</span>
        ))}
      </div>
    )
  else return null
}
/**
 * @summary Checks if the item passed is an array with a length greater than zero;
 *  useful if an expected item is null (GraphQL) or is an empty Array.
 *
 * @param {Array} anyArray - Any Array
 * @returns {boolean} -
 */
export const arrayCanIterate = (anyArray: any[]): boolean =>
  Array.isArray(anyArray) && anyArray.length > 0

// ignores case-sensitive
const getValue = (value: string) =>
  typeof value === "string" ? value.toUpperCase() : value

/**
 * Filters an array of objects (one level-depth) with multiple criteria.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export const filterPlainArray = (
  array: any[],
  filters: { [x: string]: any[] },
) => {
  const filterKeys = Object.keys(filters)
  return array?.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores an empty filter
      if (!filters[key].length) return true
      return filters[key].find(
        filter => getValue(filter) === getValue(item[key]),
      )
    })
  })
}

/**
 * Filters an array of objects using custom predicates.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export const filterArray = (
  array: any[],
  filters: { [x: string]: (arg0: any) => unknown },
) => {
  const filterKeys = Object.keys(filters)
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== "function") return true
      return filters[key](item[key])
    })
  })
}

export const windowExists = (): boolean => typeof window !== "undefined"

export const documentExists = () => typeof document !== "undefined"

/**
 * Pass models object as it's currently structured in our codebase, and it will return single models with model-groups filtered down to the base model for that group
 * @author Tyler
 * @param {Model[]} models
 * @returns {Object}
 */

export const reduceModelGroups = (models: Model[]) => {
  return models?.map((node: Model) => {
    if (node?.model)
      return {
        isFeatured: node?.featuredModel,
        model: node?.model,
        featuredColor: node?.featuredColor?.colors[0]?._key,
      }

    // If current iteration is a model group, return the first model that has a name without any spaces
    // For example, this will return the LE model not the LE AWD model
    // If none such models exist, fallback is return the first model
    if (node?.models) {
      // const filtered = node?.models?.find(
      //   (node: any) => !node?.model?.name?.includes(" ") || node
      // )
      const filtered =
        node?.models?.find((node: any) => node.featuredModel === true) ||
        node?.models[0]
      return {
        isFeatured: filtered?.featuredModel,
        model: filtered?.model,
        featuredColor: filtered?.featuredColor?.colors[0]._key,
      }
    }
  })
}

export const reduceFeaturedModelGroups = (models: Model[]) => {
  return models?.map((node: Model) => {
    if (node?.model)
      return {
        isFeatured: node?.featuredModel,
        model: node?.model,
        featuredColor: node?.featuredColor?.colors[0]?._key,
      }
    //if multiple models- only returns the featured
    if (node?.models) {
      const filtered = node?.models?.find(
        (node: any) => node.featuredModel == true,
      )

      return {
        isFeatured: filtered?.featuredModel,
        model: filtered?.model,
        featuredColor: filtered?.featuredColor?.colors[0]._key,
      }
    }
  })
}

export const createOfferGroupByYear = (offers: any) => {
  // Flatten all nodes
  const allNodes = offers.flatMap(
    (offer: any) =>
      offer?.group.flatMap((group: any) => group?.nodes ?? []) ?? [],
  )

  // Group nodes by year
  const groupedByYear = allNodes.reduce((acc: any, node: any) => {
    const year = node.year
    if (!acc[year]) {
      acc[year] = {
        fieldValue: year,
        nodes: [],
      }
    }
    acc[year].nodes.push(node)
    return acc
  }, {})

  // Convert the object to an array of groups
  const groups = Object.values(groupedByYear)

  return groups
}

/**
 * Chunk an array into smaller arrays of a specified size
 * @author Tyler
 * @param {Array} arr Array that is to be broken down into chunks
 * @param {number} size Size of each chunk
 * @returns {Array}
 */

export const chunkArray = (arr: any, size: number) =>
  Array.from({ length: Math.ceil(arr?.length / size) }, (v, i) =>
    arr?.slice(i * size, i * size + size),
  )

/**
 * Bold and italicise words from a string search query
 * @author Stu
 * @param {string} input The full string that may or may not contain words to be bolded/italicised
 * @param {string[]} wordsToBold An array string values that should be bolded/italicised
 * @returns {string}
 */

export const boldedWords = (input: string, wordsToBold: string[]) => {
  if (!input) return
  return input.replace(
    new RegExp("(\\b)(" + wordsToBold.join("|") + ")(\\b)", "ig"),
    "$1<b><i>$2</i></b>$3",
  )
}

/**
 * Returns corrected slide number, based on the length of an array of slides.
 * This function's logic is required in order to share the accurate slide position with the Tealium event call
 * @author Stu
 * @param {number} slideNumber The full string that may or may not contain words to be bolded/italicised
 * @param {number} arrayLength An array string values that should be bolded/italicised
 * @returns {number}
 */

export const updateSlideNumber = (
  slideNumber: number,
  arrayLength: number,
): number => {
  if (slideNumber < 0) {
    return arrayLength - 1
  } else if (slideNumber < arrayLength) {
    return slideNumber
  }

  return 0
}

export const seriesNameMap = new Map([
  ["landcruiser", "Land Cruiser"],
  ["highlanderhybrid", "Highlander Hybrid"],
  ["highlander", "Highlander"],
  ["corollahybrid", "Corolla Hybrid"],
  ["corollahatchback", "Corolla Hatchback"],
  ["corollacross", "Corolla Cross"],
  ["corolla", "Corolla"],
  ["chr", "C-HR"],
  ["camryhybrid", "Camry Hybrid"],
  ["camry", "Camry"],
  ["avalonhybrid", "Avalon Hybrid"],
  ["avalon", "Avalon"],
  ["venza", "Venza"],
  ["tundra", "Tundra"],
  ["tacoma", "Tacoma"],
  ["supra", "Supra"],
  ["GR Supra", "Supra"],
  ["sienna", "Sienna"],
  ["sequoia", "Sequoia"],
  ["rav4prime", "RAV4 Prime"],
  ["rav4hybrid", "RAV4 Hybrid"],
  ["rav4", "RAV4"],
  ["priusprime", "Prius Prime"],
  ["prius", "Prius"],
  ["4runner", "4Runner"],
  ["86", "GR86"],
  ["crown", "Crown"],
  ["grcorolla", "GR Corolla"],
  ["bz4x", "bZ4X"],
  ["grandhighlander", "Grand Highlander"],
])

/**
 * Returns re-formatted offer type as per instructions from 22Squared for expected Tealium event call data. i.e. Must align with similar data from Explore site
 * @author Stu
 * @param {string} originalType String value for original offer type
 * @param {string} formattedType Re-formatted valye for the provided offer type
 * @returns {string}
 */

export const reformattedOfferType = (originalType: string): string => {
  let formattedType: string
  switch (originalType) {
    case "APR":
      formattedType = "consumer-apr"
      break
    case "Lease":
      formattedType = "consumer-lease"
      break
    case "Military":
      formattedType = "military"
      break
    case "College":
      formattedType = "college"
      break
    case "Service":
      formattedType = "service"
      break
    default:
      formattedType = "Card Title Not Recognized"
  }
  return formattedType
}

/**
 * @author Stu
 * @summary Return category for vehicle series
 *
 * @param {string} series - The vehicle series
 * @returns {string} The associated category for the provided series
 */

export const vehicleCategory = (series: string) => {
  let category = ""
  // These must be lower case to work properly, avoid errors
  const carsMinivans = [
    "camry",
    "corolla",
    "avalon",
    "sienna",
    "gr86",
    "supra",
    "crown",
  ]
  const trucks = ["tundra", "tacoma"]
  const crossoversSuvs = [
    "4runner",
    "c-hr",
    "corollacross",
    "highlander",
    "rav4",
    "sequoia",
    "venza",
  ]
  const electric = ["bz4x"]
  const hybrids = ["hybrid", "prius", "rav4prime"]
  if (series) {
    const seriesFormatted = sanitizeSeries(series)
    // hybrid and crossoverSuvs cases must occur first to avoid name conflicts (e.g. camry/camry hybrid, corolla/corolla cross)
    if (hybrids.some(el => seriesFormatted.includes(el))) {
      category = "hybrids"
    } else if (crossoversSuvs.some(el => seriesFormatted.includes(el))) {
      category = "crossovers suvs"
    } else if (carsMinivans.some(el => seriesFormatted.includes(el))) {
      category = "cars minivans"
    } else if (trucks.some(el => seriesFormatted.includes(el))) {
      category = "trucks"
    } else if (electric.some(el => seriesFormatted.includes(el))) {
      category = "electric"
    }

    return category
  }
}

/**
 * Returns re-formatted vehicle category as per instructions from 22Squared for expected Tealium event call data. i.e. Must align with similar data from Explore site
 * @author Stu
 * @param {string} originalCategory String value for original offer type
 * @param {string} formattedCategory Re-formatted valye for the provided offer type
 * @returns {string}
 */

export const reformatCategory = (originalCategory: string): string => {
  let formattedCategory: string
  switch (originalCategory) {
    case "Cars & Minivan":
      formattedCategory = "cars minivans"
      break
    case "Cars & Minivans":
      formattedCategory = "cars minivans"
      break
    case "Trucks":
      formattedCategory = "trucks"
      break
    case "Hybrids":
      formattedCategory = "hybrids"
      break
    case "Hybrid & Electric":
      formattedCategory = "hybrids"
      break
    case "Crossovers & SUVs":
      formattedCategory = "crossovers suvs"
      break
    default:
      formattedCategory = originalCategory
  }
  return formattedCategory
}

/**
 
 /**
 * Returns contact dealer header text based on the view type. This value is used for analytic-ids on contact dealer modal and are essential for ObservePoint - a tagging tool.
 * @author Becca
 * @param {string} originalType String value for view type
 * @param {string} formattedType Header text for the provided view type
 * @returns {string}
 */

export const reformattedContactView = (originalType: string): string => {
  let headerText: string
  switch (originalType) {
    case "IntroView":
      headerText = "What can we do for you?"
      break
    case "ScheduleAServiceApptView":
      headerText = "How should we contact you?"
      break
    case "ConfirmZip":
      headerText = "Confirm Your Zip Code"
      break
    case "DealerLocationView":
      headerText = "Enter ZIP, state, city, or dealer name"
      break
    case "SelectDealerView":
      headerText = "Select a Dealership to Continue"
      break
    case "SelectVehicleView":
      headerText = "Were you interested in a specific vehicle?"
      break
    case "NameView":
      headerText = "What's your name?"
      break
    case "NameValidationView":
      headerText = "Did we get this right?"
      break
    case "MethodOfContactView":
      headerText = "How should we contact you?"
      break
    case "SummaryView":
      headerText = "Review & Submit Your Request"
      break
    default:
      headerText = "Error: Type Not Recognized"
  }
  return headerText
}

/**
 * Generates an inventory URL with base64 encoded parameters
 *
 * @param {InventoryParams} params Any state to encode in the returned URL
 * @returns {string} An inventory URL, with Base64 encoded state
 */
export const generateInventoryLink = (params: InventoryParams): string => {
  if (!windowExists) return

  if (!params.limit) params.limit = 20
  if (!params.sortBy) params.sortBy = "price|ASC"

  if (params.series === "86") {
    params.series = "gr86"
  } else if (params.series === "sequoiahybrid") {
    params.series = "sequoia"
  }
  // Prevent filter count of accessoryModels on inventory page from using individual model codes
  if (params.accessoryModels && typeof params.accessoryModels === "string") {
    params.accessoryModels = [params.accessoryModels]
  }

  // Encode the full state to base64
  const stateJsonString = JSON.stringify(params)
  const base64String = Buffer.from(stateJsonString).toString("base64")
  // Set the URL to include that value for the s query param
  const queryString = base64String ? `?s=${base64String}` : ""

  if (!params.series) {
    return `/inventory${queryString}`
  }
  return `/${params?.series}/inventory${queryString}`
  // return `${location?.protocol}//${location?.host}/${params?.series}/inventory${queryString}`
}

export const defaultRadiusOptions = [25, 50, 100, 250, 500, 600]

// Color helper
// If color selected is larger than the length of the list of total colors available, reset the selected color to index 0

/**
 *
 * @function
 * @author Stu Finn
 * @summary Checks if index position of seleced color is larger than the length of the color array. If so, the color index is reset to 0.
 *
 * @param {number} selectedColor Index of selected color
 * @param {number} colorListLength Length of Array of colors
 * @returns {number} Index of color to use
 */

export const selectedColorCheck = (
  colorIndex: number,
  colorListLength: number,
) => {
  // check if the color list length is too short to accomodate the color index position
  if (colorIndex >= colorListLength) {
    return 0
  }

  // Default case, if the colorPosition is smaller than the total length of the color list
  return colorIndex
}

/**
 *
 * @summary Get series specific mpg disclaimer code if available, else return default mpg disclaimer code.
 *
 * @param {string} seriesName
 * @returns {string} mpg disclaimer code
 */

export const getSeriesMpgDisclaimerCode = (
  seriesName: string,
  seriesYear: string,
) => {
  const [{ disclaimers }] = useContext(DisclaimersContext)
  const formattedSeriesName =
    typeof seriesName === "string" &&
    seriesName?.toLowerCase()?.replaceAll(" ", "_")

  let mpgDisclaimerCode = formattedSeriesName
    ? `${seriesYear}_${formattedSeriesName}_mpg`
    : null
  const containsSeriesMpgDisclaimer = mpgDisclaimerCode
    ? disclaimers?.some(
        (disclaimer: Disclaimer) => disclaimer.code === mpgDisclaimerCode,
      )
    : false
  if (!containsSeriesMpgDisclaimer) mpgDisclaimerCode = "epa_2022"

  return `[${mpgDisclaimerCode}]`
}

/**
 *
 * @summary Get custom model names associated with an accessory model code in the series Model filter
 *
 * @param {Filter} seriesModelFilter Filters - a series Model filter
 * @param {string} accessoryModelCode - string of comma separated accessory codes that make up an accessory model
 * @returns {string[]} array of custom model names and/or custom group model names
 */

export const getModelNamesByAccessoryModelCodes = (
  seriesModelFilter: Filter,
  accessoryModelCodes: string[],
) => {
  let modelNames: string[] = []
  const customModels = seriesModelFilter?.values?.filter(
    value => value?.accessoryCodes,
  )
  // Custom Model
  if (customModels.length > 0) {
    customModels.forEach((model, i) => {
      if (model.accessoryCodes !== accessoryModelCodes.toString()) return

      let modelName = model.description || model.name || model.title
      if (modelName) {
        modelNames.push(modelName)
      }
    })
    return modelNames
  }

  // Custom Model Group
  seriesModelFilter?.values?.forEach(filterValue => {
    filterValue?.models?.forEach((model, i) => {
      let matchedCustomModel = false
      if (Array.isArray(model.value)) {
        matchedCustomModel = model.value.every(code =>
          accessoryModelCodes.includes(code),
        )
      }
      if (!matchedCustomModel) return

      let customModelName = model.description || model.name || model.title
      if (customModelName) {
        modelNames.push(customModelName)
      }
    })
  })

  return modelNames
}

/**
 *
 * @summary Get the model names associated with model numbers in the series Model filter
 *
 * @param {Filter} seriesModelFilter Filters - a series Model filter
 * @param {string[]} modelNumbers
 * @returns {string[]} array of model names
 */

export const getModelNamesByModelNumbers = (
  seriesModelFilter: Filter,
  modelNumbers: string[],
) => {
  let modelNames: string[] = []
  if (!Array.isArray(modelNumbers)) {
    return modelNames
  }
  seriesModelFilter?.values?.forEach(value => {
    value?.models?.forEach((model, i) => {
      const matchedModelNumber = modelNumbers?.find(
        modelNumber => modelNumber === model.value,
      )
      if (!matchedModelNumber) return

      let label = model.description || model.name || model.title
      if (label) {
        label = `${label}${
          model?.seriesCategory === "Hybrids" ? " Hybrid" : ""
        }`
        modelNames.push(label)
      }
    })
  })

  return modelNames
}

/** removes duplicates from array */

export const removeDuplicates = (arr: any[]) => {
  return arr?.filter((item, index) => arr.indexOf(item) === index)
}

/** removes duplicates trims from array */

export const removeDuplicateTrims = (data: any[], key: any) => {
  const trimmedData = data
    .filter(t => !t.name.includes("-trimmed"))
    .filter(
      (item, index, self) =>
        index ===
        self.findIndex(t => {
          // Convert both values to arrays
          const itemValue = Array.isArray(item.value)
            ? item.value
            : [item.value]
          const tValue = Array.isArray(t.value) ? t.value : [t.value]

          // Check if any value in tValue is also in itemValue
          return tValue.some((val: string) => itemValue.includes(val))
        }),
    )
  return [...new Map(trimmedData.map(x => [key(x), x])).values()]
}

/**
 * @author Stu
 * @summary Changes all caps string to string with each first letter capitalized
 *
 * @param {string} str - a string that may have incorrect casing (eg. all-caps)
 * @returns {string} A string that has a capital latter at the beginning of each word.  Ideal for strings like dealership names.
 */

export const firstLettersCapital = (str: string) => {
  if (!str) return str

  return str
    ?.toLowerCase()
    ?.split(" ")
    ?.map(s => s.charAt(0).toUpperCase() + s.substring(1))
    ?.join(" ")
}

/**
 * @author Stu
 * @summary Modifies series value from inventory API, and formats it for use by generateInventoryLink() function. Also removes 4x2 and 4x4 from trucks
 *
 * @param {string} series - Original value for vehicle.series property, from the Inventory API
 * @param {boolean} lowerCase - Optional value, determines if result is returned as lower case (defaul is true)
 * @returns {string} A reformatted value for "series", acceptable format for generateInventoryLink()
 */
export const sanitizeSeries = (series: string, lowerCase = true) => {
  if (series && typeof series === "string") {
    const sanitized = series
      .replaceAll(" ", "")
      .replace("4x2", "")
      .replace("4x4", "")
      .replace("4X2", "")
      .replace("4X4", "")

    if (lowerCase) {
      return sanitized.toLocaleLowerCase()
    } else {
      return sanitized
    }
  } else {
    return undefined
  }
}

/**
 * @author Stu
 * @summary removes 4x2 and 4x4 from series names. Includes cases for four-by designation without preceding space, and for both uppercase and lowercase "x".
 *
 * @param {string} series - Original value for vehicle series
 * @returns {string} A reformatted value for "series", without four-by designation
 */

export const remove4by = (modelName: string): string | undefined => {
  if (modelName && typeof modelName === "string") {
    return modelName
      .replaceAll(" 4x2", "")
      .replaceAll(" 4x4", "")
      .replaceAll(" 4X2", "")
      .replaceAll(" 4X4", "")
      .replace("4x2", "")
      .replace("4x4", "")
      .replace("4X2", "")
      .replace("4X4", "")
  } else {
    return undefined
  }
}

export const monthsMap = new Map([
  ["January", "enero"],
  ["February", "febrero"],
  ["March", "marzo"],
  ["April", "abril"],
  ["May", "mayo"],
  ["June", "junio"],
  ["July", "julio"],
  ["August", "agosto"],
  ["September", "septiembre"],
  ["October", "octubre"],
  ["November", "noviembre"],
  ["December", "diciembre"],
])

/**
 * @summary Formats series name for series related links (e.g. series page, series offers, series accessories, etc.).
 *
 * @param {string} seriesName - Original value for series name property.
 * @returns {string | null} A reformatted value for series name, acceptable format for series related links excluding inventory link.
 */
export const normalizeSeriesNameForLink = (
  seriesName: string,
): string | null => {
  if (!seriesName) return null

  let series = `${seriesName
    ?.toLowerCase()
    ?.replaceAll("hybrid", "")
    ?.trim()
    ?.replace(" ", "-")}`

  if (series === "gr-supra") {
    series = "supra"
  }
  return series
}

/**
 * @summary Get the formatted link value for a the cms field cta.
 * @param {CTA} cta
 */

export const getCTALinkValue = (cta: CTA) => {
  let to = ""
  if (cta?.linkType === "external") {
    to = cta?.externalUrl
  } else if (cta?.linkType === "internal") {
    to = `/${cta?.internalLink?.slug?.current}`
  } else if (cta?.linkType === "anchor") {
    const internalLink = cta?.internalLink?.slug?.current
      ? `/${cta?.internalLink?.slug?.current}`
      : ""
    const anchorValue = cta?.anchor && cta?.anchor?.replace("#", "")
    if (internalLink) {
      to = `${internalLink}/#${anchorValue}`
    } else {
      to = `#${anchorValue}`
    }
  }
  return to
}

/**
 * @summary detect mobile portrait and mobile landscape
 * @returns {boolean}
 */

export const detectMobileView = (): boolean => {
  if (
    window.innerWidth <= 768 ||
    (window.innerHeight < window.innerWidth && window.innerWidth < 1024)
  ) {
    return true
  } else {
    return false
  }
}
/**
 * @summary detect mobile portrait and mobile landscape
 * @param {Array[]} arr
 * @param {Array} item
 * @returns {number} index of top array that the array item is found
 */

export const getIndexOfNestedArrays = (arr: any[], item: any) => {
  for (var i = 0; i < arr.length; i++) {
    var index = arr[i].indexOf(item)
    if (index > -1) {
      return i
    }
  }
}

/**
 * @summary slice array into smaller arrays of a certain length
 * @param {Array[]} arr
 * @param {Array} length
 * @returns {Array[]} multiple arrays of specified length
 */

export const sliceArrayIntoArrays = (arr: any[], length: number) => {
  return arr?.reduce((all, one, i) => {
    const ch = Math.floor(i / length)
    all[ch] = [].concat(all[ch] || [], one)
    return all
  }, [])
}

/* ObservePoint IPs to "allow list" */
/* Cloudfront does not return data from location headers for these IPs */
/* List of IPs was obtained from https://help.observepoint.com/article/251-observepoint-whitelisting-and-proxy-list */
export const observePointAllowList = [
  "35.161.29.125",
  "35.80.146.28",
  "44.236.49.34",
  "44.241.27.217",
  "52.12.141.28",
  "54.241.3.7",
  "54.215.6.50",
  "54.235.253.73",
  "54.83.59.214",
  "52.65.182.248",
  "54.79.15.183",
  "15.185.57.68",
  "157.241.42.229",
  "54.233.189.245",
  "54.233.210.11",
  "15.222.131.206",
  "52.60.121.52",
  "35.180.220.29",
  "15.236.248.245",
  "35.157.253.184",
  "35.159.7.189",
  "54.246.83.204",
  "79.125.118.108",
  "15.160.84.44",
  "15.161.218.194",
  "54.248.228.95",
  "54.250.119.103",
  "3.36.203.19",
  "3.37.11.36",
  "13.228.165.163",
  "13.228.165.59",
  "13.246.212.5",
  "13.245.215.220",
  "18.100.207.253",
  "18.100.219.173",
  "16.63.141.189",
  "16.63.130.2",
  "3.28.139.193",
  "3.28.233.214",
  "35.176.213.160",
  "35.176.42.87",
]
export const colorToSnakeCase = (str: string) =>
  str
    .replace(/\[.*?\]/g, "") //remove square brackets and contents
    .replace(/\s*$/, "") //remove trailing space
    ?.replaceAll(" ", "_") //replace spaces with underscores
    ?.toLowerCase()

export const smartPathDealersMap = new Map([
  ["10124", "https://smartpath.autonationtoyotamallofgeorgia.com"],
  ["09196", "https://smartpath.autonationtoyotapinellaspark.com"],
  ["10104", "https://smartpath.autonationtoyotathorntonroad.com"],
  ["09206", "https://smartpath.autonationtoyotaweston.com"],
  ["09210", "https://smartpath.autonationtoyotawinterpark.com"],
  ["09231", "https://smartpath.beavertoyotastaugustine.com"],
  ["10108", "https://smartpath.cherokeecountytoyota.com"],
  ["09198", "https://smartpath.clearwatertoyota.com"],
  ["32110", "https://smartpath.cloningertoyota.com"],
  ["10090", "https://smartpath.cobbcountytoyota.com"],
  ["09076", "https://smartpath.earlstewarttoyota.com"],
  ["39046", "https://smartpath.florencetoyota.com"],
  ["32148", "https://smartpath.ashevilletoyota.com"],
  ["10134", "https://smartpath.jimellistoyota.com"],
  ["39052", "https://smartpath.jimhudsontoyota.com"],
  ["32154", "https://smartpath.markjacobsontoyota.com"],
  ["39067", "https://smartpath.mymidlandstoyota.com"],
  ["32129", "https://smartpath.hickorytoyota.com"],
  ["10015", "https://smartpath.miltonmartintoyota.com"],
  ["09193", "https://smartpath.palmbeachtoyota.com"],
  ["09174", "https://smartpath.panamacitytoyota.com"],
  ["39066", "https://smartpath.toyotaofcolumbia.com"],
  ["10044", "https://smartpath.pittstoyota.com"],
  ["32001", "https://smartpath.toyotaofgreensboro.com"],
  ["01083", "https://smartpath.sandmountaintoyota.com"],
  ["32096", "https://smartpath.scottclarkstoyota.com"],
  ["09226", "https://smartpath.southdadetoyota.com"],
  ["01064", "https://smartpath.sunnykingtoyota.com"],
  ["32126", "https://smartpath.townandcountrytoyota.com"],
  ["39028", "https://smartpath.toyotaofeasley.com"],
  ["09228", "https://smartpath.treasurecoasttoyotaofstuart.com"],
  ["39068", "https://smartpath.spartanburgtoyota.com"],
  ["32024", "https://smartpath.masseytoyota.com"],
  ["10099", "https://smartpath.walkerjonestoyota.com"],
  ["10131", "https://smartpath.stonemountaintoyota.com"],
  ["09199", "https://smartpath.centralfloridatoyota.com"],
  ["10035", "https://smartpath.northgeorgiatoyota.com"],
  ["09218", "https://smartpath.toyotaofmelbourne.com"],
  ["09107", "https://smartpath.toyotaofhollywood.com"],
  ["09247", "https://smartpath.i75toyota.com"],
  ["09096", "https://smartpath.arlingtontoyota.com"],
  ["09234", "https://smartpath.venicetoyota.com"],
  ["09182", "https://smartpath.gatorlandtoyota.com"],
  ["10039", "https://smartpath.franklintoyota.com"],
  ["09229", "https://smartpath.autonationtoyotafortmyers.com"],
  ["09230", "https://smartpath.getteltoyotaoflakewood.com"],
  ["10096", "https://smartpath.lowetoyota.com"],
  ["10094", "https://smartpath.atlantatoyota.com"],
  ["10112", "https://smartpath.i95toyota.com"],
  ["09202", "https://smartpath.mikeerdmantoyota.com"],
  ["09242", "https://smartpath.wesleychapeltoyota.com"],
  ["01033", "https://smartpath.mckinnontoyota.com"],
])

/**
 * @summary This is used to check if all accessory codes in a comma separated list exist in a 2 dimentional array of accessory codes.
 *
 * @param {string} codes - comma separated list of accessory codes
 * @param {string[][]} allCodes - 2 dimentional array of accessory codes
 * @returns {boolean} - true if all codes exist, false if any do not exist
 */

export const checkAllAccessoryCodesExist = (
  codes: string,
  allCodes: string[][],
) => {
  const codesToCheck = codes?.split(",")
  const allCodesArray = allCodes?.flat()?.join(",")?.split(",")

  return codesToCheck.every(code => allCodesArray?.includes(code))
}

export const getPageLoadTime = () => {
  let performanceEntries = performance.getEntriesByType("navigation")

  if (performanceEntries.length > 0) {
    let navigationEntry = performanceEntries[0]

    let pageLoadTime = navigationEntry.loadEventEnd - navigationEntry.startTime
    Math.round(pageLoadTime * 10) / 10
    return pageLoadTime
  } else {
    return null
  }
}

/**
 * @summary This returns the powertrain of a series based on all the series models fuelType values.
 * @param {Array[]} codes - comma separated list of accessory codes
 * @returns {string} - returns one of the following requested tagging values: "Gas", "Hybrid", "BEV", "Plug-In", "Mixed"
 */
export const getTealPowertrain = (models: any[]) => {
  let fuelType = models[0][0]?.model.fuelType
  switch (fuelType) {
    case "Battery Electric":
      fuelType = "BEV"
      break
    case "Plug-in Hybrid":
      fuelType = "Plug-In"
      break
  }
  const tealPowertrain = models.every(
    group =>
      group.every(model => model.model.fuelType == "Gas") ||
      group.every(model => model.model.fuelType == "Hybrid") ||
      group.every(model => model.model.fuelType == "Battery Electric") ||
      group.every(model => model.model.fuelType == "Plug-in Hybrid"),
  )

  let result = tealPowertrain ? fuelType : "Mixed"

  return result
}

export const slugify = (str?: string) => {
  if (!str) return undefined
  return slugifyFn(str, {
    lower: true,
    remove: /[*+~.(),'"!:@]/g,
  })
}

export const combineSeriesAndSeriesHybridModels = (seriesData: any[]) => {
  const combinedData: { [key: string]: any } = {}

  for (const item of seriesData) {
    const name = item.name.replace(" Hybrid", "")

    if (combinedData[name]) {
      // Combine the two items
      combinedData[name] = {
        ...combinedData[name],
        ...item,
        name: name,
      }
    } else {
      combinedData[name] = item
    }
  }

  return Object.values(combinedData)
}

export const formatModelName = (modelName: string, seriesName: string) => {
  // Get the value from the seriesNameMap if needed or return the original seriesName
  const seriesNameValue = seriesNameMap.get(seriesName) || seriesName

  // If the seriesNameValue exists, replace it in the modelName
  if (seriesNameValue) {
    return modelName?.replace(`${seriesNameValue} `, "")
  }

  // If the seriesNameValue does not exist, return the original modelName
  return modelName
}
export const formatSeriesSlug = (series: string) => {
  if (series === "GR Supra") {
    return "supra"
  }
  return slugify(series)?.replace("-", "")
}

export const cleanUpModels = (
  models: Model[],
  seriesName: string,
  year: number,
) => {
  let options: Model[] = []
  const sanitizedModels = models
    ?.filter(
      (node: Model) =>
        node?.name != null && !node._id.includes("drafts") && node.year == year,
    )
    .map((data: Model) => {
      return {
        ...data,
        name: formatModelName(data.name, seriesName),
      }
    })
  // sort models alphabetically
  sanitizedModels &&
    (options = sanitizedModels?.sort(
      (a: { name: string }, b: { name: string }) =>
        a?.name?.localeCompare(b?.name),
    ))
  return options
}

export const removeExpiredCoupons = (coupons: Coupon[]) => {
  const today = moment()
  const activeCoupons = coupons
    ?.map((coupon: Coupon) => {
      const endDate = coupon.expires && moment(coupon.expires).endOf("day")
      const startDate = coupon.starts && moment(coupon.starts).startOf("day")
      if (today.isBetween(startDate, endDate)) {
        return coupon
      } else {
        return null
      }
    })
    ?.filter(coupon => coupon)

  return activeCoupons
}
